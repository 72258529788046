<template>
  <v-container style="background-color: white; height: 100vh" fluid>
    <v-row class="pb-3">
      <v-col cols="12">
        <BackArrow :route="'atendimentos'"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card elevation="5" rounded="lg">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h2 style="color: #292867;">Detalhes</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field class="pb-4" label="Solicitação" v-model="item.solicitacao" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Protocolo" v-model="item.protocolo" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Status" v-model="item.status" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Benefício / Operadora" v-model="item.beneficio" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Abertura" v-model="item.abertura" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Atendido" v-model="item.atendido" dense readonly></v-text-field>
                <v-textarea auto-grow rows="1" row-height="15" label="Descrição" v-model="item.descricao" dense readonly></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="6">
        <TimeLine :caseId="id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CaseService from "../../services/salesforce/CaseService";
import ContractService from "../../common/ContractService"
import BackArrow from '@/components/covid/back-arrow/index'
import TimeLine from '@/components/case/TimeLine'

export default {
  name: 'AtendimentoDetalhar',
  components: {
    BackArrow,
    TimeLine
  },
  data: () => ({
    id: '',
    item: {
      arquivos: [],
      comentarios: [],
    }
  }),
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["showmsg"]),
    async loadDescribe() {
      await this._contractservice.FindbyDescribe();
      this.describeContract = JSON.parse(sessionStorage.getItem("describe-contract"));
    },
    getdescribe(name, campo) {
      return this._contractservice.dsDescribe(this.describeContract, name, campo);
    },
    requestDetails() {
      this._caseService.FindById(this.id).then(response => {
        this.configureItem(response.data.records[0]);
      }).catch(() => {
        this.showmsg({
          text:  "Erro ao carregar informações",
          type: "error",
        });
      }).finally(() => {});
    },
    configureItem(itemData) {
      let beneficio = itemData.contract__r && itemData.contract__r.benefits__c
              ? this.getdescribe(itemData.contract__r.benefits__c, "Benefits__c")
              : "";

      beneficio += itemData.contract__r && itemData.contract__r.carrier_2__r
              ? " / " + itemData.contract__r.carrier_2__r.comercial_name__c
              : "";

      let contract = itemData.contract__r && itemData.contract__r.policy__c ? itemData.contract__r.policy__c : "";
      contract += " - " + (itemData.contract__r && itemData.contract__r.Name ? itemData.contract__r.Name : "");

      this.item = {
        ...this.item,
        protocolo: itemData.caseNumber,
        abertura: this.$util.vueFormatDateToBR(itemData.createdDate),
        atendido: itemData.Account ? itemData.Account.Name : itemData.suppliedName,
        solicitacao: itemData.subject,
        status: this.getdsdescribe(itemData.status, "Status"),
        descricao: itemData.description,
        contract,
        beneficio
      };
    },
    async loadDescribeStatus() {
      await this._caseService.FindbyDescribe();
      this.describeCase = JSON.parse(sessionStorage.getItem("describe-case"));
    },
    getdsdescribe(name, campo) {
      return this._caseService.dsDescribe(this.describeCase, name, campo);
    },
  },
  async beforeMount(){
    this.id = this.$route.params.id;
    this._caseService = new CaseService();
    this._contractservice = new ContractService();
    await this.loadDescribeStatus();
    await this.loadDescribe();
    await this.requestDetails();
  },
};
</script>

<style scoped>

</style>
